@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  composes: a from global;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;

  color: var(--colorGrey700);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportMedium) {
    border-bottom-width: 4px;
    padding: 16px 10px 14px 10px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
.dropDown{
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-size: 14px;
  
  padding: 10px;
  z-index: 1000;
}

.dropDownMenu:hover{
  background-color: transparent;
  border:none;
}

.dropDownMenu option {
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  color: black;
}

.dropDownMenu:active {
  background-color: yellow;
}
.dropDownMenu::selection {
  background-color: red;
}

.accountSetting{
  color:rgb(62, 62, 62);
  margin: 0px;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  width: 100%;
  transition: .5;
  &:hover{
    background-color: gray;
    color: white;
    font-size: 14px;
  }
}

.navMenu{
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-bottom: solid;
}

.margR{
  margin-right: 5px;
}

