.root {
  height: 100%;
  width: 100%;
  padding: 5px;
  margin: 5px;

  &:hover{
    background-color: rgb(75, 75, 75);
    color: white;
  }
}

.root a:hover{
  color: white;
}
